.AuthenticationPage_layoutWrapperMain__3gWAw {
  min-height: calc(100vh - var(--topbarHeight))
}
@media (min-width: 768px) {
.AuthenticationPage_layoutWrapperMain__3gWAw {
    min-height: calc(100vh - var(--topbarHeightDesktop))
}
  }
.AuthenticationPage_root__2RWFg {
    /* Page is using flex: AuthenticationPage's .root takes all available space */
  flex-grow: 1;

    /* AuthenticationPage's root uses flexbox */
  display: flex
}
@media (min-width: 768px) {
.AuthenticationPage_root__2RWFg {
  justify-content: center;
  align-items: flex-start
}
    }
@media (min-width: 768px) {
.AuthenticationPage_root__2RWFg {
    /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
      url(/static/media/signup.68dd02c1.jpg);

    /* Add loading color for the div */
  background-color: var(--matterColor);

    /* Cover the whole screen with the background image */
  background-size: cover;

    /* Align the image within the container */
  background-position: center center
}
  }
.AuthenticationPage_content__3nHEa {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: var(--modalPadding);

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  border-bottom: none;
}
@media (min-width: 768px) {
.AuthenticationPage_content__3nHEa {
  flex-basis: 480px;
  flex-grow: 0;
      /* min-height: 568px; */
  padding: var(--modalPaddingMedium);
  margin-top: 12.5vh;
  margin-bottom: 12.5vh;
  border-bottom: 8px solid var(--marketplaceColor);
}
    }
.AuthenticationPage_content__3nHEa {
  padding-top: 29px;
}
/* ================ Tabs & Form ================ */
@media (min-width: 768px) {
.AuthenticationPage_tabs__1ppTt {
    margin-top: 6px
}
  }
.AuthenticationPage_tab__1SV2L {
  font-weight: var(--fontWeightSemiBold);
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold)
}
@media (min-width: 768px) {
.AuthenticationPage_tab__1SV2L {
  font-weight: var(--fontWeightSemiBold);
  line-height: 40px;
  letter-spacing: -0.9px;
  margin: 0
}
    }
.AuthenticationPage_tab__1SV2L {
  margin-top: 0;
  margin-bottom: 0
}
@media (min-width: 768px) {
.AuthenticationPage_tab__1SV2L {
    margin-top: 0
}
  }
.AuthenticationPage_form__2_HuI {
  margin-top: 24px
}
@media (min-width: 768px) {
.AuthenticationPage_form__2_HuI {
    margin-top: 30px;
    padding-top: 2px
}
  }
.AuthenticationPage_loginForm__HEmUQ {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 260px
}
@media (min-width: 768px) {
.AuthenticationPage_loginForm__HEmUQ {
    flex-basis: 330px;
    margin-top: 30px;
    padding-top: 2px
}
  }
.AuthenticationPage_signupForm__bEfKv {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 340px
}
@media (min-width: 768px) {
.AuthenticationPage_signupForm__bEfKv {
    flex-basis: 410px;
    margin-top: 30px;
    padding-top: 2px
}
  }
.AuthenticationPage_bottomWrapper__2pvFT {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}
@media (min-width: 768px) {
.AuthenticationPage_bottomWrapper__2pvFT {
  margin-top: 96px;
}
    }
.AuthenticationPage_bottomWrapper__2pvFT {

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}
/* Terms of Service modal*/
.AuthenticationPage_termsWrapper__uCQwW {
  width: 100%;
  padding-top: 40px
}
@media (min-width: 768px) {
.AuthenticationPage_termsWrapper__uCQwW {
    width: 604px;
    padding-top: 11px
}
  }
.AuthenticationPage_termsHeading__OCaVq {
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px
}
@media (min-width: 768px) {
.AuthenticationPage_termsHeading__OCaVq {
  font-size: 40px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 48px;
  letter-spacing: -1px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px
}
    }
.AuthenticationPage_termsHeading__OCaVq {
  margin: 0 0 19px 0
}
@media (min-width: 768px) {
.AuthenticationPage_termsHeading__OCaVq {
    margin: 0 0 19px 0
}
  }
/* ================ Hide Top bar in screens smaller than 768px  ================ */
.AuthenticationPage_hideOnMobile__1jvqT {
  display: none
}
@media (min-width: 768px) {
.AuthenticationPage_hideOnMobile__1jvqT {
    display: block
}
  }
/* ================ Close icon ================ */
.AuthenticationPage_verifyClose__3W4UD {
    /* Position inside modal */
  position: fixed;
  top: 0;
  right: 0;

    /* Some content (like map) is positioning itself on top of close button without z-index handling */
  z-index: calc(var(--zIndexModal) + 1);

    /* Safari didn't know how to baseline-align svg icon and text */
  display: flex;
  align-items: flex-start;
  width: auto;

    /* Sizing (touch area) */
  padding: 24px;
  margin: 0;
  border: 0;
}
@media (min-width: 768px) {
.AuthenticationPage_verifyClose__3W4UD {
  padding: 27px 30px;
  position: absolute;
}
    }
.AuthenticationPage_verifyClose__3W4UD {

    /* Colors */
  background-color: transparent;
  color: var(--matterColor);

  cursor: pointer;
}
.AuthenticationPage_verifyClose__3W4UD:enabled:hover {
  background-color: transparent;
  color: var(--matterColorDark);
  box-shadow: none;
  text-decoration: none;
    }
.AuthenticationPage_verifyClose__3W4UD:enabled:active {
  background-color: transparent;
  color: var(--matterColorDark);
    }
.AuthenticationPage_verifyClose__3W4UD:disabled {
  background-color: transparent;
    }
.AuthenticationPage_verifyClose__3W4UD {

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}
.AuthenticationPage_closeText__1c1ZU {
    /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
.AuthenticationPage_closeText__1c1ZU {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 6px;
}
    }
.AuthenticationPage_closeText__1c1ZU {
  margin: -2.5px 0 0 0;
}
@media (min-width: 768px) {
.AuthenticationPage_closeText__1c1ZU {
  margin-top: -1px;
}
    }
.AuthenticationPage_closeIcon__3n_Wy {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 0 4px;
  box-sizing: content-box;
}
/* ================ Typography ================ */
/* Title of the modal */
.AuthenticationPage_modalTitle__3juH6 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold);
}
@media (min-width: 768px) {
.AuthenticationPage_modalTitle__3juH6 {
  font-weight: var(--fontWeightSemiBold);
  line-height: 40px;
  letter-spacing: -0.9px;
  margin: 0;
}
    }
/* Paragraph for the Modal */
.AuthenticationPage_modalMessage__xFsFe {
  margin: 18px 0 0 0;
}
@media (min-width: 768px) {
.AuthenticationPage_modalMessage__xFsFe {
  margin: 24px 0 0 0;
}
    }
/* Make the email pop */
.AuthenticationPage_email__3TlJ6 {
  font-weight: var(--fontWeightHighlightEmail);
}
/* Helper links */
.AuthenticationPage_modalHelperLink__3NCgn {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: var(--marketplaceColor);

    /* Text size should be inherited */
  text-decoration: none;
}
.AuthenticationPage_modalHelperLink__3NCgn:hover {
  text-decoration: underline;
  cursor: pointer;
    }
.AuthenticationPage_modalHelperLink__3NCgn {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}
@media (min-width: 768px) {
.AuthenticationPage_modalHelperLink__3NCgn {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }
.AuthenticationPage_modalHelperLink__3NCgn {
  color: var(--matterColor);
  margin: 0;
}
@media (min-width: 768px) {
.AuthenticationPage_modalHelperLink__3NCgn {
  margin: 0;
}
    }
/* Helper texts for the links, not the actual links */
.AuthenticationPage_modalHelperText__-dwS_ {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}
@media (min-width: 768px) {
.AuthenticationPage_modalHelperText__-dwS_ {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }
.AuthenticationPage_modalHelperText__-dwS_ {
  color: var(--matterColorAnti);
  margin: 0;
}
@media (min-width: 768px) {
.AuthenticationPage_modalHelperText__-dwS_ {
  margin: 0;
}
    }
.AuthenticationPage_modalIcon__1g-gP {
  height: 48px;
  margin-bottom: 12px
}
@media (min-width: 768px) {
.AuthenticationPage_modalIcon__1g-gP {
  height: 64px;
  margin-bottom: 23px
}
    }
.AuthenticationPage_modalIcon__1g-gP {

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px
}
@media (min-width: 768px) {
.AuthenticationPage_modalIcon__1g-gP {
    margin-top: 0
}
  }
/* ================ Errors ================ */
.AuthenticationPage_error__1SmGR {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
.AuthenticationPage_error__1SmGR {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }
.AuthenticationPage_error__1SmGR {
  margin-top: 24px;
  color: var(--failColor);
}
/* ================ Social logins & SSO ================ */
.AuthenticationPage_signupWithIdpTitle__2aezm {
  font-weight: var(--fontWeightSemiBold);
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold)
}
@media (min-width: 768px) {
.AuthenticationPage_signupWithIdpTitle__2aezm {
  font-weight: var(--fontWeightSemiBold);
  line-height: 40px;
  letter-spacing: -0.9px;
  margin: 0
}
    }
.AuthenticationPage_signupWithIdpTitle__2aezm {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  color: var(--matterColorDark)
}
@media (min-width: 768px) {
.AuthenticationPage_signupWithIdpTitle__2aezm {
    margin-top: 6px
}
  }
.AuthenticationPage_confirmInfoText__1dEBC {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
.AuthenticationPage_confirmInfoText__1dEBC {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }
.AuthenticationPage_buttonIcon__15Qjf {
  position: absolute;
  left: 0;
  margin-left: 20px;
}
.AuthenticationPage_socialButtonWrapper__bNq0o {
  margin-bottom: 6px
}
@media (min-width: 768px) {
.AuthenticationPage_socialButtonWrapper__bNq0o {
    margin-top: 8px
}
  }
.AuthenticationPage_socialButtonsOr__YbZ6c {
  width: 100%;
  height: 32px;
  margin: 28px 0 20px 0;
  text-align: center;
  position: relative;
  background-color: var(--matterColorLight)
}
.AuthenticationPage_socialButtonsOr__YbZ6c:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
@media (min-width: 768px) {
  .AuthenticationPage_socialButtonsOr__YbZ6c {
    height: 34px;
    margin: 15px 0;
  }
}
.AuthenticationPage_socialButtonsOrText__34yuc {
  background-color: var(--matterColorLight);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

